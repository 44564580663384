* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  background-image: url("./bg.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#logo {
  background-image: url("./logo.svg");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 3rem;
  font-weight: 600;
}

h1,
p {
  font-family: Lato;
}
